@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.fibonacci-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .fibonacci-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3490dc;
    color: #fff;
    font-weight: bold;
    margin: 5px;
  }
  
  .fibonacci-1 {
    background-color: #f6993f;
  }
  
  .fibonacci-2 {
    background-color: #9561e2;
  }
